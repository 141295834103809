.App {
  text-align: center;
  display: block;
}

header {
  height: 40px;
  width: 100%;
  color: #5049d6;
  display: flex;
  align-items: center;
  font-weight: 600;
  padding: 4px 20px;
  box-sizing: border-box;
  background: #ededff;
  margin-bottom: 5px;
}

.logo {
  margin-right: 10px;
  font-size: 21px;
  height: 100%;
  padding: 6px 0;
}

.diagnostics {
  margin-top: 20px;
  color: white;
  background: #c53d3d;
}

.logo img {
  height: 100%;
  width: auto;
}

.panel {
  height: calc(100vh - 50px);
  display: flex;
  box-sizing: border-box;
  position: relative;
  overflow: hidden;
}

select {
  margin-left: 20px;
  border-radius: 5px;
}

.top-menu {
  flex: 1;
  text-align: right;
}

a,
.href {
  color: #5049d6;
  text-decoration: none;
}

.href {
  background: transparent;
  border: none;
  border-radius: none;
  font-size: inherit;
  font-weight: inherit;
  cursor: pointer;
  margin: 0;
  padding: 0;
}

.link {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  gap: 5px;
}

.output-code {
  text-align: left;
  overflow-y: scroll;
  flex: 1;
  padding-right: 20px;
  padding-left: 10px;
  padding-bottom: 100px;
}

.chunk {
  padding: 15px 15px;
  background: #f8f8fe;
  border-radius: 10px;
  box-shadow: 0 5px 10px #00000014;
  margin-top: 30px;
}

.chunk > h3 {
  margin: 0;
  font-size: 16px;
}

.chunk > pre {
  font-size: 12px;
  background: transparent !important;
  margin: 0;
  margin-top: 10px;
}

.is-entry {
  font-weight: 700;
}
